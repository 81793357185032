<template>
  <div class="sales-unit-setting">
    <PageTitle title="銷售人員設定" btn="新增" @btnClick="openDialog('create')" />

    <FiltersContainer>
      <BaseElInput v-model="nameSearch" clearable placeholder="輸入人員名稱" @keypress.enter.native="refresh(); tableOptions.page = 1;" @clear="refresh(); tableOptions.page = 1;">
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh(); tableOptions.page = 1;" />
      </BaseElInput>
    </FiltersContainer>

    <section>
      <BaseTable v-loading="loading" :data="salesUnitList" empty-text="暫無數據">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="name" label="名稱" align="center" />
        <BaseElTableColumn prop="profit" label="抽潤比例" align="center" />
        <BaseElTableColumn prop="SalesGroup" label="群組" align="center">
          <template slot-scope="scope">
            {{ (scope.row.SalesGroup && scope.row.SalesGroup.name) || '-' }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="order" label="排序" align="center" />
        <BaseElTableColumn label="操作" fixed="right" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="openDialog('update'), selectRow=scope.row, findSalesUnit()"
              @delete="deleteDialog=true, selectRow=scope.row"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="salesUnitCount"
        @pageChange="refresh"
      />
    </section>

    <!-- Dialog -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="resetForm()">
      <section>
        <BaseElForm ref="form" :model="formData" label-position="top" :rules="formRules">
          <BaseElFormItem label="名稱 ：" prop="name">
            <BaseElInput v-model="formData.name" placeholder="請輸入名稱" />
          </BaseElFormItem>

          <BaseElFormItem label="抽潤比例 ：" prop="profit">
            <BaseElInput v-model="formData.profit" type="number" placeholder="請輸入抽潤比例" />
            <i class="icon__percentage">%</i>
            <!-- <BaseElInputNumber v-model="formData.profit" :precision="2" :step="0.01" :min="0" :max="1" /> -->
            <!-- <div style="color: gray; font-size: 14px;">範例：90%請填寫0.9，65%請填寫0.65</div> -->
          </BaseElFormItem>

          <BaseElFormItem label="群組" prop="SalesGroupId">
            <BaseElSelect
              v-model="formData.SalesGroupId"
              clearable
              placeholder="請選擇群組"
            >
              <BaseElSelectOption
                v-for="item in salesGroupOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </BaseElSelect>
          </BaseElFormItem>

          <BaseElFormItem label="排序 ：" prop="order">
            <BaseElInput v-model="formData.order" placeholder="請輸入順序" />
          </BaseElFormItem>
        </BaseElForm>
      </section>

      <div slot="footer">
        <BaseElButton plain @click="showDialog = false, resetForm()">
          取消
        </BaseElButton>
        <BaseElButton type="primary" @click="dialogConfirm">{{ (dialogType === 'create')? '新增' : '儲存' }}</BaseElButton>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteSalesUnit(), deleteDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import { noEmptyRules, isDigitRules, rangeRules, integerRules } from '@/validation'
import EmptyBlock from '@/components/EmptyBlock.vue'
import {
  CreateSalesUnit,
  GetSalesUnit,
  GetSalesUnitCount,
  FindSalesUnit,
  UpdateSalesUnit,
  DeleteSalesUnit,
  GetSalesGroup,
  GetSalesGroupCount,
} from '@/api/sales'

// Utils
import { getAllDataFromApi } from '@/utils/helper'
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { dialogTitle } from '@/utils/dialog'

export default {
  name: 'SalesUnitSetting',
  components: { DeleteDialog, EmptyBlock },
  computed: {
    ...mapGetters([
      'shop',
    ]),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },

    dialogTitle () {
      return dialogTitle(
        this.dialogType,
        {
          create: '新增銷售人員',
          update: '編輯銷售人員',
        },
      )
    },

    salesGroupOptions () {
      return this.salesGroupList.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
  },
  data: () => ({
    loading: false,
    nameSearch: '',
    // Dialog
    showDialog: false,
    dialogType: 'create',
    deleteDialog: false,
    selectRow: null,
    selectSalesUnit: null,

    salesUnitCount: 0,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    formData: {
      name: '',
      profit: 0,
      order: 100,
      SalesGroupId: '',
    },

    formRules: {
      name: noEmptyRules('請輸入類別名稱'),
      profit: [isDigitRules(false), integerRules(), rangeRules(0, 100)],
      order: [noEmptyRules('請輸入排序'), isDigitRules(), rangeRules()],
    },

    salesUnitList: [],
    salesGroupList: [],
  }),

  async mounted () {
    await this.refresh()
  },
  methods: {
    async refresh () {
      this.loading = true
      await this.getSalesCategoryGroup()
      await this.getSalseUnit()
      await this.getSalesUnitCount()
      this.loading = false
    },

    openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },

    async dialogConfirm () {
      if (!await this.checkForm()) return
      const type = this.dialogType
      if (type === 'create') this.createSalesUnit()
      if (type === 'update') this.updateSalesUnit()
      this.showDialog = false
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        name: '',
        profit: 0,
        order: 100,
        SalesGroupId: '',
      }
    },

    //= > 創建銷售類別
    async createSalesUnit () {
      const form = this.formData
      try {
        await CreateSalesUnit({
          shopId: this.shop,
          name: form.name,
          profit: this.convertPercentageToNumber(form.profit),
          order: form.order,
          SalesGroupId: form.SalesGroupId || null,
        })
        await this.refresh()
        this.$message.success('新增成功!')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 取得銷售類別
    async getSalseUnit () {
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit
      try {
        const res = await GetSalesUnit({
          shopId: this.shop,
          start: startIndex,
          limit,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.salesUnitList = res
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 取得銷售類別總數
    async getSalesUnitCount () {
      try {
        const res = await GetSalesUnitCount({
          shopId: this.shop,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })

        this.salesUnitCount = res
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 取得指定銷售類別
    async findSalesUnit () {
      try {
        const res = await FindSalesUnit({
          shopId: this.shop,
          id: this.selectRow.id,
        })

        this.formData = JSON.parse(JSON.stringify(res))
        this.formData = { ...this.formData, profit: this.convertNumberToPercentage(this.formData.profit) }
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 更新銷售類別
    async updateSalesUnit () {
      try {
        const form = this.formData
        await UpdateSalesUnit({
          shopId: this.shop,
          id: this.selectRow.id,
          name: form.name,
          profit: this.convertPercentageToNumber(form.profit),
          order: form.order,
          SalesGroupId: form.SalesGroupId || null,
        })

        await this.refresh()
        this.$message.success('更新成功!')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    //= > 刪除銷售類別
    async deleteSalesUnit () {
      try {
        await DeleteSalesUnit({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        await this.refresh()
        this.$message.success('刪除成功!')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    // async getSalesCategoryGroup () {
    //   const res = await GetSalesGroup({
    //     shopId: this.shop,
    //     type: 'salesUnit',
    //   })

    //   this.salesGroupList = res
    // },

    async getSalesGroupCount () {
      try {
        const res = await GetSalesGroupCount({
          shopId: this.shop,
        })
        return res.count
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },

    async getSalesCategoryGroup () {
      const max = await this.getSalesGroupCount()
      const config = {
        shopId: this.shop,
        start: 0,
        limit: 100,
        type: 'salesUnit',
      }
      const res = await getAllDataFromApi(max, GetSalesGroup, config)

      this.salesGroupList = res
    },

    convertPercentageToNumber (value) {
      return (value / 100).toFixed(2)
    },

    convertNumberToPercentage (value) {
      return Math.trunc(value * 100)
    },
  },
}
</script>

<style scoped lang="scss">
.icon__percentage {
  position: absolute;
  top: 50%;
  right: 0;
  color: #767676;
  transform: translateY(-50%);
}
</style>
